export default class  {
    static languageList = ['ko', 'en'];
    //static targetLanguage = Resource.languageList[0];

    //static get ID() {
    //    return Resource.id[Resource.targetLanguage];
    //}

    //static setLanguage(lang) {
    //    Resource.targetLanguage = lang;
    //}

    //static getCurrentLanguage() {
    //    return Resource.targetLanguage;
    //}

    //static getLanguageList() {
    //    return Resource.languageList;
    //}

    //static Site = {
    //    kor: 1,   //국문
    //    eng: 2,   //영문
    //}

    static Language = {
        ko: 'ko',
        en: 'en',
    }

}