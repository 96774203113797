import React, { Component } from 'react';

import Resource from '../resource/id';

import NavMenuKor from '../components/NavMenuKor.jsx';
import NavMenuEng from '../components/NavMenuEng.jsx';


export class Layout extends Component {
    static displayName = Layout.name;

    constructor(props) {
        super(props);

        this.props = props;
        this.state = {
            //disUI: this.displayUI(),
            language: null,
        }

        this.initLanguage();
    }

    /* onChangeLanguage = (e) => {
        Resource.setLanguage(e.target.value);
        this.setState({});
    } */

    initLanguage = () => {
        let language = localStorage.getItem('Language');

        if (language === null || language === undefined) {
            language = Resource.Language.ko;
        }

        this.state.language = language;
    }


    /* resizeUI() {
        this.setState({ disUI: this.displayUI() });
    } */

    componentDidMount() {
       // window.addEventListener('resize', () => this.resizeUI());
    }

    /* displayUI = () => {
        let displayUI = [];

        let widthSize = window.outerWidth;

        if (widthSize < 768) {
            displayUI.push(
                <>
                    <NavMenu />
                      {this.props.children}
                    <Footer />
                </>
            );
        } else if (widthSize >= 1025) {
            displayUI.push(
                <>
                    <NavMenu />
                      {this.props.children}
                </>
            );
        } else {
            displayUI.push(
                <></>
            );
        }
        return displayUI;
    } */

    displaynavLanguage = () => {
        let displaynavLanguage = [];
        let language = localStorage.getItem('Language');

        if (language === null || language === undefined) {
            language = Resource.Language.ko;
        }

        if (language === Resource.Language.ko) {
            displaynavLanguage = <>
                <NavMenuKor />
            </>;
        } else {
            displaynavLanguage = <>
                <NavMenuEng />
            </>;
        }

        return displaynavLanguage;

    }

    render() {
        let navLanguage = this.displaynavLanguage();

        //setTimeout(() => { this.resizeUI() }, 500);
        //let displayUI = this.state.disUI;

        return (
           <>
             {/* <NavMenu /> */}
               {navLanguage}
             {this.props.children}
           </>
        );
    }
}
